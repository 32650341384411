import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";

export default class WalletService {
    getGemTopUps(): Promise<GemTopUpView[]> {
        return mapToDataOnly<GemTopUpView[]>(axios.get(`Wallet/GemTopUp`));
    }
    getBalance(userId: string): Promise<{ balance: number }> {
        return mapToDataOnly<{ balance: number }>(axios.get(`Admin/Wallet/Balance/${userId}`));
    }
}

export interface GemTopUpView {
    id: string,
    price: number,
    dollarPrice: number,
    count: number,
    key: string
}