import { Card, CardContent, Typography } from '@mui/material'

export default function SectionView(props: { children?: any, title?: string, description?: string }) {
    return <>
        {
            props.title && <Typography sx={{ color: 'charcoal.main' }}>
                <span className='size-9 font-bold'>{props.title}</span>
            </Typography>
        }
        {
            props.description && <div className="size-5 pt-sm">
                <span className='font-regular cool-grey text'>{props.description}</span>
            </div>
        }
        <Card sx={{ borderRadius: '8px', boxShadow: 'unset', marginTop: '20px' }}>
            <CardContent>
                {props.children}
            </CardContent>
        </Card>
    </>
}