import React from 'react'
import { Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from '@mui/material';
import UserService from '../../../../services/user/UserService';
import SelectComponent from '../../../shared/fields/select/SelectComponent';
import WalletService from '../../../../services/financial/WalletService';
import PurchaseService, { ProductType } from '../../../../services/financial/PurchaseService';
import { toast } from 'react-toastify';
import { CustomButton } from '../../../shared/button/ButtonComponent';

export default class WalletChargeModalComponent extends React.Component<{ onSubmit?: () => void, userId: string }, {
    gemTopUps: {
        value: string,
        label: string,
    }[], selectedPackage?: string,
    confirmPaid: boolean,
    description: string,
    pendingRequest: boolean
}> {
    userService: UserService
    walletService: WalletService
    purchaseService: PurchaseService

    constructor(props: any) {
        super(props)
        this.state = { gemTopUps: [], confirmPaid: false, pendingRequest: false, description: '' };
        this.userService = new UserService();
        this.walletService = new WalletService();
        this.purchaseService = new PurchaseService();
    }

    componentDidMount(): void {
        this.load()
    }

    load = () => {
        this.walletService.getGemTopUps().then((response) => {
            this.setState({
                gemTopUps: response.map((item) => {
                    return {
                        label: String(item.count) + " " + 'Gem pack' + " " + `($ ${item.dollarPrice})`,
                        value: item.id
                    }
                })
            })
        })
    }

    discard = () => this.setState({ description: '', selectedPackage: undefined })

    submit = () => {
        this.setState({ pendingRequest: true })
        this.purchaseService.make(this.props.userId, { productId: this.state.selectedPackage as string, isPaymentFromWallet: false, productType: ProductType.Gem, description: this.state.description }).then((response) => {
            toast.success('The wallet has been charged successfully')
            this.load()
            this.props.onSubmit && this.props.onSubmit()
            this.discard()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        const { confirmPaid, description, pendingRequest } = this.state

        return <>
            <Grid container minHeight="100%">
                <Grid item xs={3}>
                    <div className='pt-lg'>
                        <label>Select a Gem Pack:</label>
                        <SelectComponent variant='filled' items={this.state.gemTopUps} title={'package'} onSelect={(value) => this.setState({ selectedPackage: value })} selected={this.state.selectedPackage} />
                    </div>
                </Grid>
                {/* <Grid item xs={3}>
                    <div className='pt-lg'>
                        <label>Select user payment method:</label>
                        <SelectComponent variant='filled' items={this.state.gemTopUps} title={'package'} onSelect={(value) => this.setState({ selectedPackage: value })} selected={this.state.selectedPackage} />
                    </div>
                </Grid> */}
                {/* <Grid item xs={4}></Grid> */}
                <Grid item xs={9}></Grid>
                <Grid item xs={4}>
                    <div className='pt-lg'>
                        <label>Description:</label>
                        <TextField
                            placeholder="Type your description here"
                            multiline
                            value={description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            rows={4}
                            variant='filled'
                            className='full-width'
                        />
                    </div>
                </Grid>
            </Grid>
            <div className='pt-lg'>
                <FormControlLabel sx={{ pb: 2, fontSize: '12px !import' }} control={<Checkbox checked={confirmPaid} onChange={() => this.setState({ confirmPaid: !confirmPaid })} size="small" />} label="I accept that this user already paid for this amount" />
            </div>
            <div className='float-end'>
                {
                    pendingRequest ?
                        <CustomButton sx={{ padding: '8px 30px', fontSize: '14px' }} variant="contained" disabled>
                            <CircularProgress color="inherit" size={25} />
                        </CustomButton> :
                        <CustomButton sx={{ padding: '8px 30px', fontSize: '14px' }} onClick={() => this.submit()} variant='contained'>Top up wallet</CustomButton>
                }
            </div>
        </>
    }
}