import { Routes } from "react-router-dom";
import BaseRoutes from "./routing/BaseRoutes";
import IdentityRoutes from "./routing/IdentityRoutes";
import UserRoutes from "./routing/UserRoutes";

export default function Routing() {
    return (
        <Routes>
            {BaseRoutes()}
            {IdentityRoutes()}
            {UserRoutes()}
        </Routes>
    )
}