import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import React from 'react'
import { CustomButton } from '../button/ButtonComponent'
import IModalState, { IActionType, IModalProps } from './ModalComponent.models'

export default class ModalComponent extends React.PureComponent<IModalProps, IModalState> {
    render(): React.ReactNode {
        let style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: this.props.width ?? 550,
            borderRadius: '8px',
            border: `0 solid`,
            bgcolor: '#ffffff',
        }
        let { children, handleClose, title } = this.props

        return <>
            <Modal
                open={true}
                onClose={handleClose && handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <Box sx={style}>
                        {title && <Typography sx={{ borderBottom: '1px solid #ebeef3', p: 2, color: 'charcoal.main', fontSize: 16 }}>
                            <span className='font-bold'>{title}</span>
                        </Typography>}
                        <div style={{ padding: '16px' }}>
                            {children}
                            {this.actionsView()}
                        </div>
                    </Box>
                </div>
            </Modal>
        </>
    }

    actionsView() {
        let { hasCloseBtn, handleClose } = this.props
        let actions: IActionType[] = this.props.actions || []
        let leftStyle = {
            position: 'absolute' as 'absolute',
            left: '8px',
        }
        hasCloseBtn && actions.push({ event: handleClose, label: 'Close', variant: 'outlined' })

        return <>
            {
                this.props.actions && <div className='pt-lg flex row-reverse'>
                    {
                        this.props.actions.map((action) => {
                            return <div className='pl-sm' style={action.isLeft ? leftStyle : {}}>
                                {
                                    action.loading ?
                                        <CustomButton sx={{ padding: '8px 30px', fontSize: '16px' }} disabled color={action.color} variant={action.variant || 'contained'}>
                                            <CircularProgress color="inherit" size={25} /></CustomButton>
                                        :
                                        <CustomButton sx={{ padding: '8px 30px', fontSize: '16px' }} onClick={action.event} color={action.color} variant={action.variant || 'contained'}>
                                            {action.label}</CustomButton>
                                }
                            </div>
                        })
                    }
                </div>
            }
        </>
    }
}