import { Container, Grid } from '@mui/material'
import React from "react"
import StaffPersenceService from '../../services/staff/StaffPersenceService'
import IDashboardState from './DashboardComponent.models'

export default class Dashboard extends React.PureComponent<{}, IDashboardState> {
    staffPersenceService: StaffPersenceService
    constructor(props: any) {
        super(props)
        this.state = { staffPresenceToday: { loading: false, rows: [], totalRows: 0 } }
        this.staffPersenceService = new StaffPersenceService();
    }

    componentDidMount(): void {
    }

    render(): React.ReactNode {
        return <>
            <Grid container sx={{ width: '100%' }} minHeight="100%">
                <Grid item xs={12}></Grid>
            </Grid>
        </>
    }
}