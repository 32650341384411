import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Outlet } from "react-router-dom"
import SideBar from './side-bar/SideBar'
import { Container } from '@mui/material'
import { CustomButton } from '../../component/shared/button/ButtonComponent'
import { Logout } from '@mui/icons-material'
import axios from 'axios'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  minHeight: '100vh',
  backgroundColor: '#ebeef3',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function DefaultLayout() {
  const [open, setOpen] = React.useState(true)

  const handleDrawer = () => {
    setOpen(!open)
  }

  const logout = () => {
    localStorage.removeItem('token')
    axios.defaults.headers.common['authorization'] = ``
    window.location.href = '/sign-in'
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar />
      </Drawer>
      <Main open={open}>
        <Container sx={{ padding: '100px' }}>
          <CustomButton className='float-end' color='error' sx={{ fontSize: '15px' }} variant="text" onClick={() => { logout() }}>
            <span className='font-bold pr-sm'>Logout</span>
            <Logout fontSize='small' />
          </CustomButton>
          <Outlet />
        </Container>
      </Main>
    </Box>
  )
}