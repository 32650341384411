import { Typography } from "@mui/material";

export enum AccessTypes {
    Private = 0,
    LimitedUsers = 1,
    Anybody = 2,
}

export function accessTypeDisplayCaption(type: number) {
    let types = ['Just myself', 'Selected users', 'Anybody'];
    return types.length <= type ? type : types[type];
}

export default function accessTypeDisplayBadge(type: number) {
    return <Typography sx={{fontSize: '14px'}}>{accessTypeDisplayCaption(type)}</Typography>;
}