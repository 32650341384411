import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import IUserListState, { IUserListProps } from './UserListComponent.models'
import UserService from '../../../services/user/UserService'
import StripedGrid from '../../shared/grid/striped/StripedGridComponent'
import SectionView from '../../shared/section-view/SectionViewComponent'
import { Visibility, FilterAlt } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import UserFilterModalComponent from './filter/UserFilterModalComponent'

export default class UserListComponent extends React.Component<IUserListProps, IUserListState> {
    userService: UserService

    constructor(props: any) {
        super(props)
        this.state = { data: { totalRows: 0, rows: [] }, loading: false, searchQuery: '', isOpenFilterModal: false, email: '' }
        this.userService = new UserService();
    }

    componentDidMount(): void {
        this.fetchData()
    }

    fetchData = (pageSize?: number, pageSkip?: number) => {
        this.setState({ loading: true })
        this.userService.getUsers({ pageSize: pageSize ?? 10, pageSkip: pageSkip ?? 0 }, { fullName: this.state.searchQuery, email: this.state.email }).then((response) => {
            this.setState({ data: response })
        }).finally(() => this.setState({ loading: false }))
    }

    render(): React.ReactNode {
        const { data } = this.state

        let columns: GridColDef[] = [
            { field: 'email', headerName: 'Email address', width: 250, sortable: false },
            { field: 'firstName', headerName: 'First name', sortable: false },
            { field: 'lastName', headerName: 'Last name', width: 200, sortable: false },
            { field: 'subscription', headerName: 'Subscription', sortable: false },
            { field: 'statues', headerName: 'Account status', width: 170, sortable: false },
            { field: 'createDate', headerName: 'Date joined', width: 210, sortable: true },
            {
                field: 'actions',
                type: 'actions',
                editable: true,
                width: 200,
                getActions: (params) => [
                    <NavLink to={`/user-details?id=${params.id}`} className="flex">
                        <Visibility className='pr-md' sx={{ color: 'primary.main' }} fontSize='small' />
                    </NavLink>
                ],
            },
        ]

        return <>
            <SectionView title='Users' description="Manage Leitner Pro users">
                <StripedGrid hasPagination loading={this.state.loading} columns={columns} rows={data.rows} totalRows={data.totalRows} onChangePage={(query) => this.fetchData(query.pageSize, query.pageSkip)}>
                    <StripedGrid.Header>
                        <StripedGrid.HeaderItem text={'Users'} value={""} />
                        <div className='flex items-center'>
                            <StripedGrid.Search placeholder='Search users...' onChange={(value) => {
                                this.setState({ searchQuery: value }, () => this.fetchData())
                            }} />
                            <FilterAlt color='primary' className='pl-md' onClick={() => this.setState({ isOpenFilterModal: true })} />
                        </div>
                    </StripedGrid.Header>
                </StripedGrid>
            </SectionView>
            {this.state.isOpenFilterModal && <UserFilterModalComponent email={this.state.email} filter={(value) => {
                this.setState({ email: value }, () => this.fetchData())
            }} close={() => this.setState({ isOpenFilterModal: false })} />}
        </>
    }
}