import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { ISelectComponentProps } from './SelectComponent.models'

export default class SelectComponent extends React.Component<ISelectComponentProps, {}> {
    render(): React.ReactNode {
        const { items, title, onSelect, selected, variant } = this.props
        return <>
            <Select
                className='full-width'
                size="small"
                variant={variant}
                inputProps={{ 'aria-label': 'Without label' }}
                value={selected}
                onChange={e => onSelect(e.target.value)}
            >
                <MenuItem disabled value="null">
                    <span style={{ color: '#a29eac' }}>Select {title}...</span>
                </MenuItem>
                {
                    items.map((workspace) => {
                        return (
                            <MenuItem key={workspace.value} value={workspace.value}>{workspace.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </>
    }
}

export interface SelectionView<T> {
    value: T;
    label: string;
}