import React from 'react'
import UserService from '../../../services/user/UserService'
import SectionView from '../../shared/section-view/SectionViewComponent'
import IUserListState, { IUserListProps, MenuItemsData } from './UserDetailsComponent.models'
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import './UserDetailsComponent.scss'
import UserSubscriptionComponent from '../subscription/UserSubscriptionComponent'
import UserWalletComponent from '../wallet/UserWalletComponent'
import UserInformationComponent from '../information/UserInformationComponent'
import EditUserComponent from '../edit-user/EditUserComponent'
import WalletService from '../../../services/financial/WalletService'
import SubscriptionService from '../../../services/subscription/SubscriptionService'
import ProductComponent from '../product/list/ProductComponent'

export default class UserDetailsComponent extends React.Component<IUserListProps, IUserListState> {
    userService: UserService
    walletService: WalletService
    subscriptionService: SubscriptionService

    constructor(props: any) {
        super(props)
        this.state = { loading: false, searchQuery: '', currentState: 'information' }
        this.userService = new UserService();
        this.walletService = new WalletService();
        this.subscriptionService = new SubscriptionService();
    }

    componentDidMount(): void {
        this.fetchData()
        this.loadBalance()
        this.loadSubscription()
    }

    loadBalance = () => {
        this.walletService.getBalance(this.props.id).then((response) => {
            this.setState({ balance: response.balance })
        })
    }

    loadSubscription = () => {
        this.subscriptionService.getUserSubscription(this.props.id).then((response) => {
            this.setState({ subscription: response })
        })
    }

    fetchData = () => {
        this.setState({ loading: true })

        this.userService.getUserInformation(this.props.id).then((response) => {
            this.setState({ user: response })
        }).finally(() => this.setState({ loading: false }))
    }

    renderBody = (state: 'information' | 'subscription' | 'wallet' | 'edit-user' | 'transfer-gem' | 'leitner-box' | 'flashcard-pack') => {
        switch (state) {
            case "information":
                return <UserInformationComponent user={this.state.user} balance={this.state.balance} subscription={this.state.subscription} />;
            case "subscription":
                return <UserSubscriptionComponent userId={this.props.id} />;
            case "wallet":
                return <UserWalletComponent userId={this.props.id} />;
            case "edit-user":
                return <EditUserComponent userId={this.props.id} />;
            case "leitner-box":
                return <ProductComponent type='box' userId={this.props.id} />;
            case "flashcard-pack":
                return <ProductComponent type='pack' userId={this.props.id} />;
            default:
                return <></>
        }
    }

    render(): React.ReactNode {
        return <>
            <SectionView title='View user details'>
                <Grid container sx={{ width: '100%' }} minHeight="100%" className='user-details'>
                    <Grid item xs={2}>
                        <List sx={{ mt: '0' }} disablePadding className='side-bar'>
                            {
                                MenuItemsData.map((menuItem, index) => (
                                    <ListItem key={index} disablePadding className={this.state.currentState === menuItem.to ? ' active ' : ''}>
                                        <ListItemButton disableTouchRipple disableRipple onClick={() => { this.setState({ currentState: menuItem.to }) }}>
                                            <ListItemIcon sx={{ minWidth: 42, fontSize: 14, color: this.state.currentState === menuItem.to ? 'white' : 'charcoal.main' }}>
                                                {menuItem.icon}
                                            </ListItemIcon>
                                            <ListItemText sx={{ color: this.state.currentState === menuItem.to ? 'white' : 'charcoal.main' }}>
                                                <span className='font-medium size-4'>{menuItem.label}</span>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                    <Grid item xs={10} sx={{ pl: 5 }}>
                        {this.renderBody(this.state.currentState)}
                    </Grid>
                </Grid>
            </SectionView>
        </>
    }
}