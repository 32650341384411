import React from 'react'
import { Grid, TextField } from '@mui/material'
import { IActionType } from '../../../shared/modal/ModalComponent.models'
import ModalComponent from '../../../shared/modal/ModalComponent'

export default class UserFilterModalComponent extends React.Component<{ close: () => void, filter: (value: string) => void, email?: string }, { email: string }> {

    constructor(props: any) {
        super(props)
        this.state = { email: '' }
    }

    componentDidMount(): void {
        if (this.props.email != null)
            this.setState({ email: this.props.email })
    }

    render(): React.ReactNode {
        const { email } = this.state

        let actions: IActionType[] = [
            {
                event: () => {
                    this.props.filter(this.state.email)
                    this.props.close()
                }, label: 'Filter'
            },
            { event: () => this.props.close(), label: 'Cancel', variant: 'outlined', isLeft: true },
        ]

        return <>
            <ModalComponent width={360} handleClose={this.props.close} actions={actions} title='Filter'>
                <Grid container minHeight="100%">
                    <Grid item xs={12}>
                        <label>Email</label>
                        <TextField
                            sx={{ pb: 2 }}
                            fullWidth
                            id="password"
                            label="Type here"
                            variant="filled"
                            value={email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </ModalComponent>
        </>
    }
}