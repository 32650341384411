import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Logo from '../../assets/images/logo/LeitnerPro.png'

export default class IdentityLayout extends React.Component<{}, {}> {
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
    }

    render(): React.ReactNode {
        return <div>
            <Paper className="paper" style={{
                height: '100vh',
                backgroundRepeat: 'round',
            }} sx={{ bgcolor: 'pale_grey.main' }}>
                <Stack sx={{ height: '30vh', width: '100%', bgcolor: 'primary.main' }} justifyContent="center" alignItems="center">
                    {this.renderBrandView()}
                </Stack>
                <Grid container sx={{ height: '70vh', pt: 9 }}>
                    <Grid item xs={12} container justifyContent="center">
                        {this.renderView()}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    }

    renderBrandView() {
        return <div className="text center">
            <img alt="" loading="lazy" src={Logo} />
            <Typography sx={{ pb: 1, pt: 1 }} variant="h5" color="white"><span className="font-bold">Leitner Pro</span></Typography>
            <Typography color="white"><span className="font-thin">Administration dashboard</span></Typography>
        </div>
    }

    renderView() {
        return <>
            <Box sx={{ width: 320 }}>
                <Outlet />
            </Box>
        </>
    }
}