import React from "react"
import { Box, Typography } from "@mui/material"
import INotFoundState, { INotFoundProps } from './NotFoundComponent.models'
import noResultImg from '../../../assets/images/content/no-search-results.png'

export default class NotFoundComponent extends React.PureComponent<INotFoundProps, INotFoundState> {
    render(): React.ReactNode {
        const { image, subTitle, title } = this.props

        return <Box className='pa-md flex column items-center' sx={{ margin: 'auto', textAlign: 'center' }}>
            <img src={image ?? noResultImg} alt="" className='pb-md' />
            <Typography component="div" className='py-md' sx={{ color: 'charcoal.dark' }}>
                <span className="font-bold size-6">{title ?? 'The desired page was not found :('}</span>
            </Typography>
            <Typography component="div" className='pb-md' sx={{ color: 'charcoal.light' }}>
                <span className="font-regular size-6">{subTitle}</span>
            </Typography>
        </Box>
    }
}