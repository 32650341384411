import { Box, CardContent, Checkbox, CircularProgress, Fade, FormControlLabel, TextField, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { CustomButton } from "../../shared/button/ButtonComponent";
import IdentityService from "../../../services/identity/IdentityService";
import IState, { IProps } from "./SignUpModels";
import { toast } from "react-toastify";

export default class SignUp extends React.PureComponent<IProps, IState> {
    identityService: IdentityService
    constructor(props: any) {
        super(props)
        this.state = { collapse: false, pendingRequest: false, confirmPassword: null, showPassword: false, password: null, username: null }
        this.identityService = new IdentityService()
    }

    componentDidMount() {
        this.setState({ collapse: true })
    }

    validation = (): boolean => {
        let { password, confirmPassword, username } = this.state

        if (password === null || confirmPassword === null || username === null) return false
        else if (confirmPassword !== password) return false
        return true
    }

    submit = () => {
        if (!this.validation()) return

        let { password, username } = this.state
        this.setState({ pendingRequest: true })

        this.identityService.signUp({ password: password as string, username: username as string }).then((response) => {
            if (response) toast.success('Sign in was successful.')
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { pendingRequest, showPassword, username, password, confirmPassword } = this.state;

        return <Fade timeout={1000} in={this.state.collapse}>
            <CardContent>
                <Typography component="div" variant="h4" sx={{ pb: 2 }}>
                    <span className="font-bold">Create an account</span>
                </Typography>
                <Typography variant="subtitle2">
                    Already have an account?
                    <NavLink to="/sign-in" className="pl-1">Sign in</NavLink>
                </Typography>
                <Box sx={{ pt: 5, pb: 1 }}>
                    <Typography variant="subtitle2" color="gray">
                        Create an account to use HRM. It'll take no time
                    </Typography>
                </Box>
                <Box sx={{ pt: 2, pb: 1 }} color="grey">
                    <TextField
                        sx={{ pb: 2 }}
                        fullWidth
                        id="email"
                        label="Email"
                        type="email"
                        value={username}
                        error={username === ""}
                        onChange={(e) => this.setState({ username: e.target.value })}
                        focused
                        variant="standard"
                    />
                    <TextField
                        sx={{ pb: 2 }}
                        fullWidth
                        value={password}
                        error={password === "" || (password !== null && password.length < 8)}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        id="password"
                        label="Password"
                        placeholder=" "
                        type={showPassword ? "text" : "password"}
                        variant="standard"
                    />
                    <TextField
                        sx={{ pb: 2 }}
                        fullWidth
                        error={confirmPassword === "" || (confirmPassword !== "" && confirmPassword !== password)}
                        value={confirmPassword}
                        onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                        id="confirm-password"
                        label="Confirm password"
                        variant="standard"
                        type={showPassword ? "text" : "password"}
                    />
                    <FormControlLabel sx={{ pb: 2, fontSize: 10 }} control={<Checkbox checked={showPassword} onChange={() => this.setState({ showPassword: !showPassword })} size="small" />} label="Show password" />
                </Box>
                {
                    pendingRequest ?
                        <CustomButton sx={{ borderRadius: "10px" }} variant="contained" disabled fullWidth>
                            <CircularProgress color="inherit" size={20} />
                        </CustomButton> :
                        <CustomButton sx={{ borderRadius: "10px" }} variant="contained" fullWidth onClick={this.submit}>Sign up</CustomButton>
                }
            </CardContent>
        </Fade>
    }
}