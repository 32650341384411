import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";
import { PaginationQuery } from "../../component/shared/grid/pagination/PaginationComponent.models";
import { GridResult } from "../../component/shared/grid/striped/StripedGridComponent.models";
import { AccessTypes } from "../../component/enums/AccessTypes";
import { Statuses } from "../../component/enums/Statuses";

export default class LeitnerBoxService {
    getList(paginationQuery: PaginationQuery, dto: { userId: string, name?: string }): Promise<GridResult<LeitnerBoxDTO>> {
        return mapToDataOnly<GridResult<LeitnerBoxDTO>>(axios.get(`Admin/LeitnerBox/GetByUserId`, { params: { ...paginationQuery, ...dto } }));
    }

    getById(leitnerBoxId: string): Promise<LeitnerBoxDetailsView> {
        return mapToDataOnly<LeitnerBoxDetailsView>(axios.get(`Admin/LeitnerBox/${leitnerBoxId}`));
    }

    update(leitnerBoxId: string, dto: UpdateLeitnerBoxDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/LeitnerBox/${leitnerBoxId}`, dto));
    }
}

export interface LeitnerBoxDTO extends BasicInformation {
    id: string;
    activeUser: number | null;
    finalPrice: number | null;
    updatedAt: string;
}

export interface LeitnerBoxDetailsView extends BasicInformation {
    id?: string;
    activeUser: number | null;
    finalPrice: number | null;
    updatedAt: string;
    category?: string
    description?: string
}

export interface UpdateLeitnerBoxDTO extends BasicInformation {
    categoryId?: string;
    description: string;
}

export interface BasicInformation {
    createdAt?: string;
    name: string;
    price: number | null;
    offPrice: number | null;
    accessType: AccessTypes;
    status: Statuses;
}