import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";

export default class CategorySerivce {
    getTitleValues(): Promise<CategoryView[]> {
        return mapToDataOnly<CategoryView[]>(axios.get(`Category`));
    }
}

export interface CategoryView {
    enTitle: string;
    icon: string;
    title: string;
    value: string
}