import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";
import { returnAuthenticationUrl } from "../../constants/constants";

export default class IdentityService {
  signIn(body: SignInDTO): Promise<{ accessToken: string }> {
    return mapToDataOnly<{ accessToken: string }>(
      axios.post(`${returnAuthenticationUrl()}/Admin/SignIn`, body)
    );
  }

  signUp(body: SignUpDTO): Promise<string> {
    return mapToDataOnly<string>(
      axios.post(`${returnAuthenticationUrl()}/SignUp`, {...body, email: body.username})
    );
  }
}

interface SignInDTO {
  username: string;
  password: string;
}

interface SignUpDTO {
  username: string;
  password: string;
}