import React from 'react'
import UserService, { UserInfoDto } from '../../../services/user/UserService'
import { Grid, Stack, Typography } from '@mui/material'
import UserProfileCard from '../../../assets/images/content/user-profile-card.png'
import { UserSubscriptionDto } from '../../../services/subscription/SubscriptionService'
import DiamondIcon from '../../../assets/images/content/Diamond.png'
import { returnBaseUrl } from '../../../constants/constants'

export default class UserInformationComponent extends React.Component<{
    user?: UserInfoDto, balance?: number
    subscription?: UserSubscriptionDto
}, {}> {
    userService: UserService

    constructor(props: any) {
        super(props)
        this.state = {}
        this.userService = new UserService();
    }

    render(): React.ReactNode {
        const { user, balance, subscription } = this.props

        return <>
            <Grid container sx={{ width: '100%' }} minHeight="100%">
                <Grid item xs={12}>
                    <Stack sx={{ bgcolor: 'pale_grey.light', borderRadius: '8px', p: 2 }}>
                        {
                            user && <div className='flex justify-between'>
                                <div className='flex'>
                                    <img className='profile-img' src={user?.imageUrl ? `${returnBaseUrl()}/FileUploader/${user?.imageUrl}` : UserProfileCard} />
                                    <div className='flex column justify-around  pl-md'>
                                        <div className='font-bold'>{user?.firstName + " " + user?.lastName}</div>
                                        <div className='size-4 charcoal-light text'>{user?.email}</div>
                                        <div className='size-4 cool-grey text'>{user?.createDate}</div>
                                    </div>
                                </div>
                                <div className='flex column justify-center'>
                                    <div className='text-primary font-bold'>{subscription?.subscription} User</div>
                                    <div className='text-primary font-bold pt-sm'><img src={DiamondIcon} /> {balance}</div>
                                </div>
                            </div>
                        }
                    </Stack>
                </Grid>
                <Grid item xs={9} sx={{ pt: 2, pr: 1 }}>
                    <Stack sx={{ bgcolor: 'pale_grey.light', borderRadius: '8px', p: 2 }}>
                        <Typography component="div" variant="h6" sx={{ pb: 2, color: 'charcoal.main' }}>
                            <span>Additional info</span>
                        </Typography>
                        <Grid container sx={{ width: '100%' }} minHeight="100%">
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <label>Age</label>
                            </Grid>
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <span className='charcoal-light text'>{user?.age}</span>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: '100%' }} minHeight="100%">
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <label>Gender</label>
                            </Grid>
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <span className='charcoal-light text'>{user?.gender}</span>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: '100%' }} minHeight="100%">
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <label>Job</label>
                            </Grid>
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <span className='charcoal-light text'>{user?.job}</span>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: '100%' }} minHeight="100%">
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <label>Country</label>
                            </Grid>
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <span className='charcoal-light text'>{user?.countryName}</span>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: '100%' }} minHeight="100%">
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <label>City</label>
                            </Grid>
                            <Grid item xs={6} sx={{ pt: 2, pr: 1 }}>
                                <span className='charcoal-light text'>{user?.city}</span>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={3} sx={{ pt: 2, pl: 1 }}>
                    <Stack sx={{ bgcolor: 'pale_grey.light', borderRadius: '8px', p: 2 }}>
                        <Typography component="div" variant="h6" sx={{ pb: 2, color: 'charcoal.main' }}>
                            <span>Actions</span>
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    }
}