import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";
import { PaginationQuery } from "../../component/shared/grid/pagination/PaginationComponent.models";
import { GridResult } from "../../component/shared/grid/striped/StripedGridComponent.models";
import { AccessTypes } from "../../component/enums/AccessTypes";
import { Statuses } from "../../component/enums/Statuses";

export default class FlashcardPackService {
    getList(paginationQuery: PaginationQuery, dto: { userId: string, name?: string }): Promise<GridResult<FlashCardPackDTO>> {
        return mapToDataOnly<GridResult<FlashCardPackDTO>>(axios.get(`Admin/FlashCardPack/GetByUserId`, { params: { ...paginationQuery, ...dto } }));
    }

    getById(leitnerBoxId: string): Promise<FlashCardPackDetailsDTO> {
        return mapToDataOnly<FlashCardPackDetailsDTO>(axios.get(`Admin/FlashCardPack/${leitnerBoxId}`));
    }

    update(leitnerBoxId: string, dto: UpdateFlashCardPackDTO): Promise<any> {
        return mapToDataOnly<any>(axios.put(`Admin/FlashCardPack/${leitnerBoxId}`, dto));
    }
}

export interface FlashCardPackDTO extends BasicInformation {
    id: string;
    activeUser: number | null;
    finalPrice: number | null;
    updatedAt: string;
}
export interface FlashCardPackDetailsDTO extends BasicInformation {
    id: string;
    activeUser: number | null;
    flashcardsCount: number | null;
    categoryId: string | null;
    description: string;
}

export interface UpdateFlashCardPackDTO extends BasicInformation {
    categoryId?: string;
    description: string;
}

export interface BasicInformation {
    createdAt?: string;
    name: string;
    price: number | null;
    offPrice: number | null;
    accessType: AccessTypes;
    status: Statuses;
}