import { combineReducers, createStore } from "redux"
import { workspaceReducer } from "./module-work-space/reducer"
import { configReducer } from "./module-config/reducer"

const rootReducer = combineReducers({
    workspace: workspaceReducer,
    config: configReducer
})

export const store = createStore(rootReducer)
