import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import UserService from '../../../../services/user/UserService'
import StripedGrid from '../../../shared/grid/striped/StripedGridComponent'
import { Visibility } from '@mui/icons-material'
import LeitnerBoxService from '../../../../services/leitnerBox/LeitnerBoxService'
import statusTypeDisplayBadge from '../../../enums/Statuses'
import accessTypeDisplayBadge from '../../../enums/AccessTypes'
import ProductViewDetailsComponent from '../details/ProductViewDetailsComponent'
import IProductState, { IProductProps } from './ProductComponent.models'
import FlashcardPackService from '../../../../services/flashcard-pack/FlashcardPackService'

export default class ProductComponent extends React.Component<IProductProps, IProductState> {
    userService: UserService
    leitnerBoxService: LeitnerBoxService
    flashcardPackService: FlashcardPackService

    constructor(props: any) {
        super(props)
        this.state = { data: { totalRows: 0, rows: [] }, loading: false, searchQuery: '', isOpenFilterModal: false, email: '' }
        this.userService = new UserService();
        this.leitnerBoxService = new LeitnerBoxService();
        this.flashcardPackService = new FlashcardPackService();
    }

    componentDidMount(): void {
        this.fetchData()
    }

    componentDidUpdate(prevProps: Readonly<IProductProps>): void {
        if (prevProps.type != this.props.type) {
            this.setState({ data: { rows: [], totalRows: 0 } })
            this.fetchData()
        }
    }

    fetchData = (pageSize?: number, pageSkip?: number) => {
        this.setState({ loading: true })

        var pagination = { pageSize: pageSize ?? 10, pageSkip: pageSkip ?? 0 }
        var query = { name: this.state.searchQuery, userId: this.props.userId }
        var request = this.props.type == 'pack' ? this.flashcardPackService.getList(pagination, query) : this.leitnerBoxService.getList(pagination, query)

        request.then((response) => {
            this.setState({ data: response })
        }).finally(() => this.setState({ loading: false }))
    }

    render(): React.ReactNode {
        const { data } = this.state
        const { type } = this.props

        let columns: GridColDef[] = [
            { field: 'name', headerName: type == 'pack' ? 'Flashcard Pack name' : 'Leitner box name', width: 180, sortable: false },
            { field: 'createdAt', headerName: 'Date created', width: 180, sortable: false },
            { field: 'finalPrice', headerName: 'Price', width: 120, sortable: false },
            { field: 'status', headerName: 'Status', sortable: false, renderCell: ({ value }) => statusTypeDisplayBadge(value) },
            { field: 'activeUser', headerName: 'Active users', width: 120, sortable: false },
            { field: 'accessType', headerName: 'Accessibility', sortable: true, renderCell: ({ value }) => accessTypeDisplayBadge(value) },
            {
                field: 'actions',
                type: 'actions',
                editable: true,
                width: 200,
                getActions: (params) => [
                    <Visibility className='pr-md' sx={{ color: 'primary.main' }} onClick={() => { this.setState({ leitnerboxId: params.row.id }) }} fontSize='small' />
                ],
            },
        ]

        return <>
            <StripedGrid hasPagination loading={this.state.loading} columns={columns} rows={data.rows} totalRows={data.totalRows} onChangePage={(query) => this.fetchData(query.pageSize, query.pageSkip)}>
                <StripedGrid.Header>
                    <StripedGrid.HeaderItem text={''} value={""} />
                    <div className='flex items-center'>
                        <StripedGrid.Search placeholder='Search Leitner boxes...' onChange={(value) => {
                            this.setState({ searchQuery: value }, () => this.fetchData())
                        }} />
                    </div>
                </StripedGrid.Header>
            </StripedGrid>
            {this.state.leitnerboxId && <ProductViewDetailsComponent onSubmit={this.fetchData} type={this.props.type} userId={this.props.userId} leitnerBoxId={this.state.leitnerboxId} close={() => this.setState({ leitnerboxId: undefined })} />}
        </>
    }
}