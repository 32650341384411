import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { Input, Stack } from '@mui/material'
import { useState } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Pagination from '../pagination/PaginationComponent'
import { IStripedGridProps } from './StripedGridComponent.models'
import { PaginationQuery } from '../pagination/PaginationComponent.models'

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  fontFamily: ['ProductSans-Regular'].join(','),
  border: 'unset',
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#dbe2eb',
    minHeight: `40px !important`,
    maxHeight: `40px !important`,
    color: theme.palette.charcoal.main,
    fontSize: '14px',
  },
  '.MuiDataGrid-cell': {
    borderBottom: 'unset',
    fontSize: '14px',
    color: theme.palette.charcoal.dark
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
  },
}))

export default function StripedGrid(props: IStripedGridProps) {
  return (
    <div>
      {props.children}
      <div style={{ height: props.height || '50vh', width: '100%' }}>
        <StripedDataGrid
          components={{
            NoRowsOverlay: () => (
              <Stack sx={{ color: 'charcoal.dark' }} className="size-4 font-regular" height="100%" alignItems="center" justifyContent="center">
                {props.noData || 'No data to show'}
              </Stack>
            ),
          }}
          rows={props.rows}
          columns={props.columns}
          disableRowSelectionOnClick
          disableVirtualization
          disableColumnSelector
          disableDensitySelector
          hideFooterSelectedRowCount
          loading={props.loading}
          disableColumnMenu
          hideFooterPagination
          hideFooter
          rowHeight={40}
        />
      </div>
      {(props.hasPagination && props.totalRows) ? <Pagination totalRows={props.totalRows} onChangePage={(query: PaginationQuery) => props.onChangePage(query)} /> : <></>}
    </div>
  )
}

function Header(props: { children?: any }) {
  return (
    <div className="py-md flex justify-between">
      {props.children}
    </div>
  )
}

function HeaderItem(props: { text: string, value: string }) {
  return (
    <div className="size-5 pb-sm">
      <span className='font-regular charcoal-main text'>{props.text}</span>
      <span className='font-bold pl-sm'>{props.value}</span>
    </div>
  )
}

function Search(props: { placeholder?: string, onChange?: (value: string) => void }) {
  const [value, setValue] = useState("")

  return (
    <div className="pb-sm">
      <Input
        value={value}
        size="small"
        placeholder={props.placeholder}
        endAdornment={
          <SearchRoundedIcon sx={{ color: 'charcoal.light' }} />
        }
        sx={{ fontSize: 16 }}
        onChange={(e) => {
          setValue(e.target.value)
          props.onChange && props.onChange(e.target.value)
        }}
      />
    </div>
  )
}


StripedGrid.Header = Header
StripedGrid.Search = Search
StripedGrid.HeaderItem = HeaderItem