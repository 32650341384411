import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";

export default class SubscriptionService {
    getList(): Promise<ShowSubscriptionDTO[]> {
        return mapToDataOnly<ShowSubscriptionDTO[]>(axios.get(`UserSetup/ShowSubscription`));
    }

    getUserSubscription(userId: string): Promise<UserSubscriptionDto> {
        return mapToDataOnly<UserSubscriptionDto>(axios.get(`Admin/Subscription/${userId}`));
    }
}

export interface ShowSubscriptionDTO {
    id: string;
    groupKey: string;
    cost: number;
    duration: string | null;
    key: string | null;
    price: number;
    dollarPrice: number;
}

export interface UserSubscriptionDto {
    subscription: string;
    expireAt: number | null;
    duration: string | null;
}