import DiamondIcon from '@mui/icons-material/Diamond'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import {  Info, ManageAccounts, AccountBalanceWallet, LineWeight, Vibration  } from '@mui/icons-material'
import { UserInfoDto } from '../../../services/user/UserService'
import { UserSubscriptionDto } from '../../../services/subscription/SubscriptionService'

export interface IUserListProps {
    id: string
}

export default interface IUserListState {
    loading: boolean
    searchQuery: string
    user?: UserInfoDto
    balance?: number
    subscription?: UserSubscriptionDto
    currentState: 'information' | 'subscription' | 'wallet' | 'edit-user' | 'transfer-gem' | 'leitner-box' | 'flashcard-pack'
}

interface IMenuItemsView {
    label: any,
    to: 'information' | 'subscription' | 'wallet' | 'edit-user' | 'transfer-gem' | 'leitner-box' | 'flashcard-pack',
    icon: any
}

export const MenuItemsData: IMenuItemsView[] = [
    {
        label: 'Information',
        to: 'information',
        icon: <Info />
    },
    {
        label: 'Edit user info',
        to: 'edit-user',
        icon: <ManageAccounts />
    },
    {
        label: 'Change subscription',
        to: 'subscription',
        icon: <AutoGraphIcon />
    },
    {
        label: 'Top up wallet',
        to: 'wallet',
        icon: <AccountBalanceWallet />
    },
    {
        label: 'Leitner boxes',
        to: 'leitner-box',
        icon: <LineWeight />
    },
    {
        label: 'Flashcard packs',
        to: 'flashcard-pack',
        icon: <Vibration />
    },
]
