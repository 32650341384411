interface IWorkspaceView {
    value: string,
    label: string,
    icon: string
}
interface IProjectView {
    value: string,
    label: string,
    parentId: string | null
}
const initialState = {
    workspaces: [],
    projects: [],
    activeWorkspace: localStorage.getItem('activeWorkspace') ? String(localStorage.getItem('activeWorkspace')) : 'null'
}
export interface IWorkspaceState {
    workspaces: IWorkspaceView[]
    activeWorkspace?: string,
    projects: IProjectView[]
}
export const workspaceReducer = (
    state: IWorkspaceState = initialState,
    action: any
) => {
    switch (action.type) {
        case "SELECT_WORKSPACE":
            return { ...state, activeWorkspace: action.payload }

        case "SET_WORKSPACES":
            return { ...state, workspaces: action.payload }
        
        case "SET_PROJECTS":
                return { ...state, projects: action.payload }
        default:
            return state
    }
}
