import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";
import { returnAuthenticationUrl } from "../../constants/constants";
import { GridResult } from "../../component/shared/grid/striped/StripedGridComponent.models";
import { PaginationQuery } from "../../component/shared/grid/pagination/PaginationComponent.models";

export default class UserService {
    getUserInformation(userId: string): Promise<UserInfoDto> {
        return mapToDataOnly<UserInfoDto>(
            axios.get(
                `${returnAuthenticationUrl()}/Admin/Users/FullUserInfo?userId=${userId}`
            )
        );
    }

    getUserData(): Promise<UserInfoDto> {
        return mapToDataOnly<UserInfoDto>(
            axios.get(`${returnAuthenticationUrl()}/User/GetUserInfo`)
        )
    }

    getUsers(paginationQuery: PaginationQuery, dto: UserParamsDto): Promise<GridResult<UserDto>> {
        return mapToDataOnly<GridResult<UserDto>>(
            axios.get(`${returnAuthenticationUrl()}/Admin/Users`, { params: { ...paginationQuery, ...dto } })
        );
    }

    add(dto: AddUserDto): Promise<string> {
        return mapToDataOnly<string>(
            axios.post(`${returnAuthenticationUrl()}/Admin/Users`, { ...dto, email: dto.userName })
        );
    }

    edit(userId: string, dto: AddUserDto): Promise<string> {
        return mapToDataOnly<string>(
            axios.patch(`${returnAuthenticationUrl()}/Admin/Users/${userId}`, { email: dto.userName, phoneNumber: dto.phoneNumber, userName: dto.userName })
        );
    }

    delete(id: string): Promise<string> {
        return mapToDataOnly<string>(
            axios.delete(`${returnAuthenticationUrl()}/Admin/Users/${id}`,)
        );
    }

    assignRole(dto: { userId: string, roleId?: string, roleName: string }): Promise<string> {
        return mapToDataOnly<string>(
            axios.post(`${returnAuthenticationUrl()}/Role/AsignRole`, { ...dto, roleId: null })
        );
    }

    getUserCount(): Promise<number> {
        return mapToDataOnly<number>(
            axios.get(`${returnAuthenticationUrl()}/Admin/Users/GetUsersCount`)
        );
    }

    getUsersEmail(userIds: string[]): Promise<{ email: string, userId: string }[]> {
        return mapToDataOnly<{ email: string, userId: string }[]>(
            axios.post(`${returnAuthenticationUrl()}/User/ListUsersEmail`, userIds)
        );
    }
}

export interface UserDto {
    id: string;
    userName: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    fullName: string;
    statues: string;  
    createDate: string;
}

interface AddUserDto {
    userName: string;
    password: string;
    phoneNumber: string;
    id?: string;
}

interface UserParamsDto {
    userRoles?: string;
    userId?: string;
    fullName?: string;
    phoneNumber?: string;
    email?: string
}

export interface UserInfoDto {
    id: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    age: number;
    gender: string;
    job: string;
    countryName: string;
    countryId: number;
    city: string;
    statues: string;
    isVerified: boolean;
    isTwoFactorEnabled: boolean;
    imageUrl: string;
    createDate: string;
    roles: string[];
    createAt: string;
    privilages: string[];
}