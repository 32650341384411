import React from 'react'
import UserService from '../../../services/user/UserService'
import PurchaseService from '../../../services/financial/PurchaseService'
import WalletService from '../../../services/financial/WalletService'
import { Stack, Typography } from '@mui/material'
import { CustomButton } from '../../shared/button/ButtonComponent'
import ChangePasswordComponent from '../change-password/ChangePasswordComponent'

export default class EditUserComponent extends React.Component<{ userId: string }, { changePasswordModal: boolean }> {
    userService: UserService
    purchaseService: PurchaseService
    walletService: WalletService

    constructor(props: any) {
        super(props)
        this.state = { changePasswordModal: false }
        this.userService = new UserService();
        this.purchaseService = new PurchaseService();
        this.walletService = new WalletService();
    }

    componentDidMount(): void {
    }

    render(): React.ReactNode {
        return <div>
            <Stack sx={{ bgcolor: 'pale_grey.light', borderRadius: '8px', p: 2 }}>
                <Typography component="div" variant="h6" sx={{ pb: 2, color: 'charcoal.main' }}>
                    <span>Basic information</span>
                </Typography>
                <div className="size-5">
                    <span className='font-regular charcoal-main text'>Password:</span>
                    <div className='pt-sm'>
                        <CustomButton sx={{ padding: '8px 24px', fontSize: '14px' }} variant="contained" onClick={() => this.setState({ changePasswordModal: true })}>Change password</CustomButton>
                    </div>
                </div>
            </Stack>
            {this.state.changePasswordModal && <ChangePasswordComponent userId={this.props.userId} close={() => this.setState({ changePasswordModal: false })} />}
        </div>
    }
}