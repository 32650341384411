import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";

let baseUrl = '/StaffPresence';

export default class StaffPersenceService {
    insert(body: InsertStaffPresenceDTO): Promise<any> {
        return mapToDataOnly<any>(
            axios.post(`${baseUrl}`, body)
        );
    }

    isWorking(): Promise<boolean> {
        return mapToDataOnly<boolean>(
            axios.get(`${baseUrl}/IsWorking`)
        );
    }

    getTodayPresence(pageSkip: number, pageSize: number): Promise<{ rows: ITodayPresenceRowDTO[], totalRows: number }> {
        return mapToDataOnly<{ rows: ITodayPresenceRowDTO[], totalRows: number }>(
            axios.get(`${baseUrl}/Today?pageSkip=${pageSkip}&pageSize=${pageSize}`)
        );
    }
}

interface InsertStaffPresenceDTO {
    projectId?: string
}

export interface ITodayPresenceRowDTO {
    id: string
    time: string
    projectId: string
    projectName: string
    workspaceId: string
    workspaceName: string
}