import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import React from "react"
import { NavLink } from 'react-router-dom'
import ISideBarState, { MenuItemsData, ISideBarProps } from './SideBar.models'
import './SideBar.scss'
import Logo from '../../../assets/images/logo/LeitnerPro.png'

export default class SideBar extends React.Component<ISideBarProps, ISideBarState> {
    constructor(props: any) {
        super(props)
        this.state = { currentRoute: '' }
    }

    componentDidMount() {
        this.setState({ currentRoute: window.location.pathname })
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.state.currentRoute) this.setState({ currentRoute: window.location.pathname })
    }

    renderBrandView() {
        return <div className="text center">
            <img alt="" loading="lazy" src={Logo} />
            <Typography sx={{ pb: 1, pt: 1, fontSize: 16 }} color="white"><span className="font-bold">Leitner Pro</span></Typography>
            <Typography sx={{ fontSize: '12px'}} color="pale_grey.light"><span className="font-thin">Administration dashboard</span></Typography>
        </div>
    }

    render(): React.ReactNode {
        return <>
            <Box className='side-bar' sx={{ overflow: 'auto' }}>
                <Stack sx={{ height: '160px', width: '100%', bgcolor: 'primary.main' }} justifyContent="center" alignItems="center">
                    {this.renderBrandView()}
                </Stack>
                {MenuItemsData.map((menuItem, index) => (
                    <List key={index} sx={{ mt: '20px' }} disablePadding>
                        {
                            menuItem.group && <ListItem disablePadding>
                                <ListItemText sx={{ padding: '6px 12px', color: 'charcoal.light' }}>
                                    <span className='pr-sm size-5 font-medium'>{menuItem.group}</span>
                                </ListItemText>
                            </ListItem>
                        }
                        {menuItem.children.map((child, childIndex) => (
                            <ListItem key={childIndex} disablePadding className={this.state.currentRoute === child.to ? ' active ' : ''}>
                                <NavLink to={child.to}>
                                    <ListItemButton disableTouchRipple disableRipple onClick={() => { this.setState({ currentRoute: child.to }) }}>
                                        <ListItemIcon sx={{ minWidth: 42, fontSize: 14, color: this.state.currentRoute === child.to ? 'white' : 'charcoal.main' }}>
                                            {child.icon}
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: this.state.currentRoute === child.to ? 'white' : 'charcoal.main' }}>
                                            <span className='font-medium size-4'>{child.label}</span>
                                        </ListItemText>
                                    </ListItemButton>
                                </NavLink>
                            </ListItem>
                        ))}
                    </List>
                ))}
            </Box>
        </>
    }
}