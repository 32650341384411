import { Stack, TablePagination } from '@mui/material'
import React from 'react'
import PaginationState, { PaginationProps } from "./PaginationComponent.models"
import './PaginationComponent.scss'

export default class PaginationComponent extends React.Component<PaginationProps, PaginationState>{

    constructor(props: PaginationProps) {
        super(props)

        this.state = { query: { pageSkip: 0, pageSize: 10 } }
    }

    changePage = (page: number) => {
        this.setState({ query: { ...this.state.query, pageSkip: page } }, () => {
            this.props.onChangePage && this.props.onChangePage(this.state.query)
        })
    }

    changeSize = (size: number) => {
        let newQuery = this.state.query
        newQuery.pageSkip = 1
        newQuery.pageSize = size
        this.setState({ query: newQuery })
        this.props.onChangePage && this.props.onChangePage(newQuery)
    }

    render(): React.ReactNode {
        const { totalRows } = this.props
        const { query } = this.state

        return (
            <Stack spacing={2} className='pt-md flex items-end'>
                <TablePagination
                    component="div"
                    count={totalRows}
                    onPageChange={(event: any, newPageSkip: number) => { this.changePage(newPageSkip) }}
                    onRowsPerPageChange={(e) => { this.changeSize(Number(e.target.value)) }}
                    page={query.pageSkip}
                    rowsPerPage={query.pageSize}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                />
            </Stack>
        )
    }
}