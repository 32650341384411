import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import DiamondIcon from '@mui/icons-material/Diamond'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'

export default interface ISideBarState {
    currentRoute: string
}
export interface ISideBarProps {
}

interface IMenuItemsView {
    group?: string,
    children:
    {
        label: any,
        to: string,
        icon: any
    }[]
}

export const MenuItemsData: IMenuItemsView[] = [
    {
        group: 'At a glance',
        children: [
            {
                label: 'Dashboard',
                to: '/',
                icon: <AlarmOnIcon />
            },
        ]
    },
    {
        group: 'System',
        children: [
            {
                label: `Users`,
                to: '/users',
                icon: <PeopleOutlineIcon />
            },
            {
                label: 'Orders',
                to: '/orders',
                icon: <ShoppingCart />
            },
        ]
    },
    {
        group: 'Financial',
        children: [
            {
                label: 'Gem management',
                to: '/topup',
                icon: <DiamondIcon />
            },
            {
                label: 'Subscriptions',
                to: '/subscriptions',
                icon: <AutoGraphIcon />
            },
        ]
    }
]
