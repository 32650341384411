import { Box, CardContent, Checkbox, CircularProgress, Fade, FormControlLabel, TextField, Typography } from "@mui/material";
import React from "react";
import { CustomButton } from "../../shared/button/ButtonComponent";
import IState, { IProps } from "./SignInModels";
import IdentityService from "../../../services/identity/IdentityService";
import axios from "axios";

export default class SignIn extends React.PureComponent<IProps, IState> {
    identityService: IdentityService

    constructor(props: any) {
        super(props)
        this.state = { collapse: false, password: null, pendingRequest: false, username: null, showPassword: false }
        this.identityService = new IdentityService()
    }

    componentDidMount() {
        this.setState({ collapse: true })
    }

    logon = (token: string) => {
        localStorage.setItem('token', token)
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`
        window.location.href = '/'
    }

    validation = (): boolean => {
        let { password, username } = this.state

        if (password === null || username === null) return false
        return true
    }

    submit = () => {
        if (!this.validation()) return

        let { password, username } = this.state
        this.setState({ pendingRequest: true })

        this.identityService.signIn({ password: password as string, username: username as string }).then((response) => {
            if (response) this.logon(response.accessToken)
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        let { pendingRequest, showPassword, username, password } = this.state;

        return <Fade timeout={1000} in={this.state.collapse}>
            <CardContent>
                <Typography component="div" variant="h5" sx={{ pb: 2, color: 'charcoal.main'}}>
                    <span className="font-bold">Sign in</span>
                </Typography>
                <Box sx={{ pt: 1, pb: 1 }} color="grey">
                    <TextField
                        sx={{ pb: 2 }}
                        fullWidth
                        id="email"
                        label="Email"
                        variant="standard"
                        value={username}
                        error={username === ""}
                        onChange={(e) => this.setState({ username: e.target.value })}
                    />
                    <TextField
                        sx={{ pb: 2 }}
                        fullWidth
                        id="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        variant="standard"
                        value={password}
                        error={password === ""}
                        onChange={(e) => this.setState({ password: e.target.value })}
                    />
                    <FormControlLabel sx={{ pb: 2 }} control={<Checkbox checked={showPassword} onChange={() => this.setState({ showPassword: !showPassword })} size="small" />} label="Show password" />
                </Box>
                {
                    pendingRequest ?
                        <CustomButton sx={{ borderRadius: "10px" }} variant="contained" disabled fullWidth>
                            <CircularProgress color="inherit" size={25} />
                        </CustomButton> :
                        <CustomButton sx={{ borderRadius: "10px" }} variant="contained" fullWidth onClick={this.submit}>Sign in</CustomButton>
                }
            </CardContent>
        </Fade>
    }
}