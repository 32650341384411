import { Action } from "./actions"

const initialState = {
    theme: localStorage.getItem('theme') ? String(localStorage.getItem('theme')) : 'light'
}
export interface IConfigState {
    theme?: string
}
export const configReducer = (
    state: IConfigState = initialState,
    action: Action
) => {
    switch (action.type) {
        case "TOGGLE_THEME":
            return { ...state, theme: action.payload }
        default:
            return state
    }
}
