import { Typography } from "@mui/material";

export enum Statuses {
    Initial,
    UnderReview,
    Approved,
    Rejected,
    Removed
}

export function statusTypeDisplayCaption(type: number) {
    let types = ['Initial', 'In review', 'Approved', 'Rejected', 'Removed'];
    return types.length <= type ? type : types[type];
}


export function statusColor(status: number) {
    let types = ['blue.main', 'orange.main', 'blue.main', 'error.main', 'error.main'];
    return types.length <= status ? 'blue.main' : types[status];
}


export default function statusTypeDisplayBadge(type: number) {
    return <Typography sx={{ color: statusColor(type), fontSize: '14px'}}>{statusTypeDisplayCaption(type)}</Typography>;
}