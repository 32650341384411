import React from "react";
import IProductViewDetailsState, { IProductViewDetailsProps, ProductDetailsView } from "./ProductViewDetailsComponent.models";
import ModalComponent from "../../../shared/modal/ModalComponent";
import { IActionType } from "../../../shared/modal/ModalComponent.models";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import LeitnerBoxService, { UpdateLeitnerBoxDTO } from "../../../../services/leitnerBox/LeitnerBoxService";
import FlashcardPackService, { UpdateFlashCardPackDTO } from "../../../../services/flashcard-pack/FlashcardPackService";
import LoadingComponent from "../../../shared/loading/LoadingComponent";
import SelectComponent, { SelectionView } from "../../../shared/fields/select/SelectComponent";
import { AccessTypes } from "../../../enums/AccessTypes";
import { Statuses } from "../../../enums/Statuses";
import CategorySerivce from "../../../../services/category/CategorySerivce";


const accessibilities: SelectionView<number>[] = [
    { label: 'Just myself', value: AccessTypes.Private },
    { label: 'Selected users', value: AccessTypes.LimitedUsers },
    { label: 'Anybody', value: AccessTypes.Anybody },
]

const statuses: SelectionView<number>[] = [
    { label: 'Initial', value: Statuses.Initial },
    { label: 'In review', value: Statuses.UnderReview },
    { label: 'Approved', value: Statuses.Approved },
    { label: 'Rejected', value: Statuses.Rejected },
    { label: 'Removed', value: Statuses.Removed },
]

export default class ProductViewDetailsComponent extends React.Component<IProductViewDetailsProps, IProductViewDetailsState> {
    leitnerBoxService: LeitnerBoxService
    flashcardPackService: FlashcardPackService
    categorySerivce: CategorySerivce
    constructor(props: any) {
        super(props)
        this.state = { loading: false, pendingRequest: false, categories: [] }
        this.leitnerBoxService = new LeitnerBoxService()
        this.flashcardPackService = new FlashcardPackService()
        this.categorySerivce = new CategorySerivce()
    }

    componentDidMount = () => {
        this.loadCategories()
        this.load()
    }

    loadCategories = () => {
        this.categorySerivce.getTitleValues().then((response) => {
            this.setState({
                categories: response.map((item) => {
                    return {
                        value: item.value,
                        label: item.enTitle
                    }
                })
            })
        })
    }

    load = () => {
        this.setState({ loading: true })
        var request = this.props.type == 'pack' ? this.flashcardPackService.getById(this.props.leitnerBoxId) : this.leitnerBoxService.getById(this.props.leitnerBoxId)

        request.then(response => {
            this.setState({ data: response as ProductDetailsView })
        }).finally(() => this.setState({ loading: false }))
    }

    submit = () => {
        this.setState({ pendingRequest: true })
        let { data } = this.state
        let body = {
            name: data?.name,
            price: data?.price,
            offPrice: data?.offPrice,
            accessType: data?.accessType,
            status: data?.status,
            description: data?.description,
            categoryId: data?.categoryId,
        }
        var request = this.props.type == 'pack' ? this.flashcardPackService.update(this.props.leitnerBoxId, body as UpdateFlashCardPackDTO) : this.leitnerBoxService.update(this.props.leitnerBoxId, body as UpdateLeitnerBoxDTO)

        request.then(() => {
            this.props.close()
            this.props.onSubmit && this.props.onSubmit()
        }).finally(() => this.setState({ pendingRequest: false }))
    }

    render(): React.ReactNode {
        const { data, loading } = this.state
        let actions: IActionType[] = [
            { event: () => this.submit(), label: 'Save changes', loading: this.state.pendingRequest },
            { event: () => this.props.close(), label: 'Close', variant: 'outlined', isLeft: true },
        ]

        return <ModalComponent width={'60%'} handleClose={this.props.close} actions={actions} title={this.props.type == 'pack' ? 'View FlashCard pack info' : 'View Leitner box info'}>
            <div>
                {!loading && data ?
                    <div>
                        <Stack sx={{ bgcolor: 'pale_grey.light', borderRadius: '8px', p: 2 }}>
                            <Typography component="div" variant="h6" sx={{ pb: 2, color: 'charcoal.main' }}><span>Basic information</span></Typography>
                            <Grid container sx={{ width: '100%' }} minHeight="100%">
                                <Grid item xs={3}>
                                    <label>Leitner box name:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={data?.name}
                                        onChange={(e) => this.setState({ data: { ...data, name: e.target.value } })}
                                        variant='filled'
                                        className="full-width pr-sm"
                                    />
                                </Grid>
                                <Grid item xs={3} pl={1}>
                                    <label>Created at:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={data?.createdAt}
                                        disabled
                                        variant='filled'
                                        className="full-width"
                                    />
                                </Grid>
                                <Grid item xs={3} pl={1}>
                                    <label>Accessibility:</label>
                                    <SelectComponent variant='filled' items={accessibilities} title={'Accessibility'}
                                        onSelect={(value: any) => this.setState({ data: { ...data, accessType: value } })} selected={data?.accessType} />
                                </Grid>
                                <Grid item xs={3} pl={1}>
                                    <label>Status:</label>
                                    <SelectComponent variant='filled' items={statuses} title={'Status'}
                                        onSelect={(value: any) => this.setState({ data: { ...data, status: value } })} selected={data?.status} />
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack sx={{ bgcolor: 'pale_grey.light', borderRadius: '8px', p: 2, mt: 2 }}>
                            <Typography component="div" variant="h6" sx={{ pb: 2, color: 'charcoal.main' }}><span>Additional info</span></Typography>
                            <Grid container sx={{ width: '100%' }} minHeight="100%">
                                <Grid item xs={3}>
                                    <label>Category:</label>
                                    <SelectComponent variant='filled' items={this.state.categories} title={'Status'}
                                        onSelect={(value: any) => this.setState({ data: { ...data, categoryId: value } })} selected={data?.categoryId} />
                                </Grid>
                                <Grid item xs={3} pl={1}>
                                    <label>Price:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={data?.price}
                                        type="number"
                                        onChange={(e) => this.setState({ data: { ...data, price: Number(e.target.value) } })}
                                        variant='filled'
                                        className="full-width"
                                    />
                                </Grid>
                                <Grid item xs={3} pl={1}>
                                    <label>Discount:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={data?.offPrice}
                                        type="number"
                                        onChange={(e) => this.setState({ data: { ...data, offPrice: Number(e.target.value) } })}
                                        variant='filled'
                                        className="full-width"
                                    />
                                </Grid>
                                <Grid item xs={3} pl={1}>
                                    <label>Final price:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={(Number(data?.price) - Number(data?.offPrice))}
                                        disabled
                                        type="number"
                                        variant='filled'
                                        className="full-width"
                                    />
                                </Grid>
                                <Grid item xs={3} pt={3}>
                                    <label>Active users:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={data?.activeUser}
                                        disabled
                                        type="number"
                                        variant='filled'
                                        className="full-width"
                                    />
                                </Grid>
                                <Grid item xs={3} pl={1} pt={3}>
                                    <label>Number of flashcards:</label>
                                    <TextField
                                        placeholder="Type here"
                                        value={data?.flashcardsCount}
                                        disabled
                                        type="number"
                                        variant='filled'
                                        className="full-width"
                                    />
                                </Grid>
                                <Grid item xs={6} pl={1} pt={3}>
                                    <label>Description:</label>
                                    <TextField
                                        placeholder="Type your description here"
                                        multiline
                                        value={data?.description}
                                        onChange={(e) => this.setState({ data: { ...data, description: e.target.value } })}
                                        rows={4}
                                        variant='filled'
                                        className='full-width'
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </div>
                    : <LoadingComponent />
                }
            </div>
        </ModalComponent >
    }
}