const getPalette = (mode: any) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                primary: {
                    light: '#7a9cec',
                    main: '#55218d',
                    dark: '#2c348c',
                    contrastText: '#fff',
                },
                secondary: {
                    light: '#98a0c1',
                    main: '#5e5fa3',
                    dark: '#3e3262',
                    contrastText: '#fff',
                },
                charcoal: {
                    light: '#808080',
                    main: '#444444',
                    dark: '#403d48',
                    contrastText: '#fff',
                },
                grey: {
                    200: '#f5f6fb',
                    300: '#cac9d0'
                },
                success: {
                    main: '#2fb126',
                    light: '#73e36b'
                },
                pale_grey: {
                    main: "#e1e3eb",
                    light: '#dbe2eb',
                },
                error: {
                    main: "#ef0049",
                    light: '#eeeff2',
                },
                blue: {
                    main: "#04a1fe",
                },
                orange: {
                    main: "#ff8345",
                }
            }
            :  {
                primary: {
                    light: '#7a9cec',
                    main: '#3c6dee',
                    dark: '#2c348c',
                    contrastText: '#fff',
                },
                secondary: {
                    light: '#98a0c1',
                    main: '#5e5fa3',
                    dark: '#3e3262',
                    contrastText: '#fff',
                },
                charcoal: {
                    light: '#a29eac',
                    main: '#7f798d',
                    dark: '#403d48',
                    contrastText: '#fff',
                },
                grey: {
                    200: '#f5f6fb',
                    300: '#cac9d0'
                },
                success: {
                    main: '#2fb126',
                    light: '#73e36b'
                },
                pale_grey: {
                    main: "#e1e3eb",
                    light: '#eeeff2',
                },
                error: {
                    main: "#e63030",
                    light: '#eeeff2',
                },
            }),
    },
})

declare module '@mui/material/styles' {
    interface Palette {
        charcoal: Palette['primary']
        pale_grey: Palette['primary']
    }
    interface PaletteOptions {
        charcoal?: PaletteOptions['primary']
        pale_grey?: PaletteOptions['primary']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        charcoal: true
        pale_grey: true
    }
}

export default getPalette