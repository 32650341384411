import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    padding: '10px 12px',
    borderRadius: '8px',
    lineHeight: 1.5,
    cursor: 'pointer',
    fontFamily: [
        'ProductSans-Regular'
    ].join(','),
});
