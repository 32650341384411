import { Route } from "react-router-dom";
import DefaultLayout from "../../layouts/default-layout/DefaultLayout";
import UserRoute from "../user/UserRoute";
import UserDetailsRoute from "../user/UserDetailsRoute";

export default function UserRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route path="/users" element={<UserRoute />}></Route>
                <Route path="/user-details" element={<UserDetailsRoute />}></Route>
            </Route>
        </>
    )
}