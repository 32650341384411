import axios from "axios";
import { mapToDataOnly } from "../base/HttpUtility";

export default class PurchaseService {
    make(userId: string, body: { productId: string, isPaymentFromWallet: boolean, productType: ProductType, description: string }): Promise<GemTopUpView[]> {
        return mapToDataOnly<GemTopUpView[]>(axios.post(`Admin/Purchase/Payment/${userId}`, body));
    }
}

export interface GemTopUpView {
    id: string,
    price: number,
    dollarPrice: number,
    count: number,
    key: string
}

export enum ProductType {
    Subscription = 0,
    Gem = 1,
}