import axios from "axios";
import { returnAuthenticationUrl } from "../../constants/constants";
import { mapToDataOnly } from "../base/HttpUtility";

export default class PasswordService {
    changePassword(userId: string, password: string): Promise<any> {
        return mapToDataOnly<any>(
            axios.put(`${returnAuthenticationUrl()}/Admin/Password`, { userId, password })
        );
    }
}