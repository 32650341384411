// const DEV_API_URL = 'https://api.leitnerpro.app';
const DEV_API_URL = 'https://localhost:7080';
const PROD_API_URL = 'https://api.leitnerpro.app';

export const returnBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') return DEV_API_URL;
  else return PROD_API_URL;
};

// const DEV_API_AUTHENTICATION_URL = 'https://localhost:7047';
const DEV_API_AUTHENTICATION_URL = 'https://auth.leitnerpro.app';
const PROD_API_AUTHENTICATION_URL = 'https://auth.leitnerpro.app';

export const returnAuthenticationUrl = () => {
  if (process.env.NODE_ENV === 'development') return DEV_API_AUTHENTICATION_URL;
  else return PROD_API_AUTHENTICATION_URL;
};