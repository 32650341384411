import { Box, Grid, Typography } from "@mui/material"
import React from "react"

export default class SelectWorkstation extends React.Component<{}, {}> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render(): React.ReactNode {
        return <Grid container sx={{ width: '100%' }} minHeight="100%">
        </Grid>
    }
}