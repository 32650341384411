import Routing from './routes/Routing'
import './assets/styles/app.scss'
import { ThemeProvider, createTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import React from 'react'
import getPalette from './composables/configs/ThemConfig'
import { AxiosConfig } from './utilities/AxiosConfig'
import { returnBaseUrl } from './constants/constants'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const mode = useSelector((state: any) => state['config'].theme)
  const theme = React.useMemo(() => createTheme(getPalette(mode)), [mode])
  let token = localStorage.getItem('token');

  if (token && window.location.pathname == '/sign-in') window.location.href = '/'
  if (!token && window.location.pathname !== '/sign-in') window.location.href = '/sign-in';

  AxiosConfig(returnBaseUrl());

  return (
    <div id="app">
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routing />
      </ThemeProvider>
    </div>
  )
}

export default App