import { Route } from "react-router-dom";
import IdentityLayout from '../../layouts/identity-layout/IdentityLayout'
import SignInRoute from "../identity/SignInRoute";
import SignUpRoute from "../identity/SignUpRoute";

export default function IdentityRoutes() {
    return (
        <>
            <Route element={<IdentityLayout />}>
                <Route path="/sign-in" element={<SignInRoute />}></Route>
                <Route path="/sign-up" element={<SignUpRoute />}></Route>
            </Route>
        </>
    )
}