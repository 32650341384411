import React from 'react'
import UserService from '../../../services/user/UserService'
import PurchaseService from '../../../services/financial/PurchaseService'
import WalletService from '../../../services/financial/WalletService'
import GrantSubscriptionComponent from './grant-subscription/GrantSubscriptionComponent'
import SubscriptionService from '../../../services/subscription/SubscriptionService'

export default class UserSubscriptionComponent extends React.Component<{ userId: string }, { isOpenWalletChangeModal: boolean, currentSubscription?: string, expireIn?: number | null, duration?: string | null }> {
    userService: UserService
    purchaseService: PurchaseService
    walletService: WalletService
    subscriptionService: SubscriptionService

    constructor(props: any) {
        super(props)
        this.state = { isOpenWalletChangeModal: false }
        this.userService = new UserService();
        this.purchaseService = new PurchaseService();
        this.walletService = new WalletService();
        this.subscriptionService = new SubscriptionService();
    }

    componentDidMount(): void {
        this.subscriptionService.getUserSubscription(this.props.userId).then((response) => {
            this.setState({ expireIn: response.expireAt, currentSubscription: response.subscription, duration: response.duration })
        })
    }

    render(): React.ReactNode {
        const { currentSubscription, expireIn, duration } = this.state

        return <div>
            <div className='flex items-center'>
                <div className="size-5 pr-xxl pb-sm">
                    <span className='font-regular charcoal-main text'>Current subscription:</span>
                    <p className='font-bold pl-sm text-primary'>{currentSubscription ?? '-'}</p>
                </div>
                {
                    currentSubscription != 'TITANIUM' &&
                    <>
                        <div className="size-5 pr-xxl pb-sm">
                            <span className='font-regular charcoal-main text'>Expires in:</span>
                            <p className='font-bold pl-sm text-primary'>{expireIn ?? '-'}</p>
                        </div>
                        <div className="size-5 pb-sm">
                            <span className='font-regular charcoal-main text'>Duration:</span>
                            <p className='font-bold pl-sm text-primary'>{duration ?? '-'}</p>
                        </div>
                    </>
                }
            </div>
            <GrantSubscriptionComponent userId={this.props.userId} />
        </div>
    }
}