import React from 'react'
import { IActionType } from '../../shared/modal/ModalComponent.models'
import ModalComponent from '../../shared/modal/ModalComponent'
import { Grid, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import PasswordService from '../../../services/user/PasswordService'

export default class ChangePasswordComponent extends React.Component<{ close: () => void, onSubmit?: () => void, userId: string }, {
    gemTopUps: {
        value: string,
        label: string,
    }[], selectedPackage?: string,
    confirmPaid: boolean,
    password: string,
    pendingRequest: boolean
}> {
    passwordService: PasswordService

    constructor(props: any) {
        super(props)
        this.state = { password: '', gemTopUps: [], confirmPaid: false, pendingRequest: false };
        this.passwordService = new PasswordService();
    }

    submit = () => {
        let { password } = this.state
        let { userId, close } = this.props

        this.setState({ pendingRequest: true })

        if (password == '')
            toast.error('Please Enter the password')
        else {
            this.setState({ pendingRequest: true })

            this.passwordService.changePassword(userId, password).then(() => {
                toast.success('The password has been changed successfully.')
                close()
            }).finally(() => this.setState({ pendingRequest: false }))
        }
    }

    render(): React.ReactNode {
        const { password } = this.state

        let actions: IActionType[] = [
            { event: () => this.submit(), label: 'Change password' },
            { event: () => this.props.close(), label: 'Cancel', variant: 'outlined', isLeft: true },
        ]

        return <>
            <ModalComponent width={360} handleClose={this.props.close} actions={actions} title='Change password'>
                <Grid container minHeight="100%">
                    <Grid item xs={12}>
                        <label>Enter new password</label>
                        <TextField
                            sx={{ pb: 2 }}
                            fullWidth
                            id="password"
                            label="Type here"
                            variant="filled"
                            value={password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </ModalComponent>
        </>
    }
}