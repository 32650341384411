import { Route } from "react-router-dom";
import DefaultLayout from '../../layouts/default-layout/DefaultLayout'
import DashboardRoute from "../base/DashboardRoute";
import NotFoundComponent from "../../component/shared/not-found/NotFoundComponent";

export default function BaseRoutes() {
    return (
        <>
            <Route element={<DefaultLayout />}>
                <Route index path="" element={<DashboardRoute />}></Route>
                <Route path="*" element={<NotFoundComponent />} />
            </Route>
        </>
    )
}