import axios from "axios";
import { toast } from "react-toastify";

export function AxiosConfig(baseUrl: string) {
    let token = localStorage.getItem('token');

    if (token !== undefined && token !== null && token !== '')
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`;

    let activeWorkspace = localStorage.getItem('activeWorkspace');
    if (activeWorkspace !== undefined && activeWorkspace !== null && activeWorkspace !== '')
        axios.defaults.headers.common['activeWorkspace'] = `${activeWorkspace}`;

    axios.defaults.baseURL = baseUrl

    axios.interceptors.request.use(function (config) {
        return config;
    }, function (error) {
        console.log(error)
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (!error.request.responseURL) {
            toast.error('Server connection problem , please check internet')
            return Promise.reject(error);
        }

        let url: string = error.request.responseURL;
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/sign-in';
        }
        return Promise.reject(error);
    });
}