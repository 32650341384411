import React from 'react'
import UserService from '../../../services/user/UserService'
import WalletChargeModalComponent from './charge/WalletChargeModalComponent'
import PurchaseService from '../../../services/financial/PurchaseService'
import WalletService from '../../../services/financial/WalletService'
import DiamondIcon from '../../../assets/images/content/Diamond.png'

export default class UserWalletComponent extends React.Component<{ userId: string }, { isOpenWalletChangeModal: boolean, balance: number }> {
    userService: UserService
    purchaseService: PurchaseService
    walletService: WalletService

    constructor(props: any) {
        super(props)
        this.state = { isOpenWalletChangeModal: false, balance: 0 }
        this.userService = new UserService();
        this.purchaseService = new PurchaseService();
        this.walletService = new WalletService();
    }

    componentDidMount(): void {
        this.loadBalance()
    }

    loadBalance = () => {
        this.walletService.getBalance(this.props.userId).then((response) => {
            this.setState({ balance: response.balance })
        })
    }

    render(): React.ReactNode {
        return <div>
            <div className='flex justify-between items-center'>
                <div className="size-5 pb-sm">
                    <span className='font-regular charcoal-main text'>Current wallet balance:</span>
                    <p className='font-bold pl-sm'><img src={DiamondIcon} /> {this.state.balance} </p>
                </div>
            </div>
            <div className=''>
                <WalletChargeModalComponent userId={this.props.userId} onSubmit={this.loadBalance} />
            </div>
        </div>
    }
}